import React from 'react'
import Layout from 'components/layout'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
// import { css } from '@emotion/core'
// import { rem } from 'utils/typography'

const SecondPage = props => {
  return (
    <Layout>
      <Container>
        <h1>Cancelled</h1>
        <p>
          You can go back to <Link to="/payments/subscribe">subscriptions</Link> page and try again.
        </p>
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default SecondPage
